import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { CopyButton, Spinner } from '../../..';
import { type StepProps } from '../UpdateApplicationModalState';

const ReviewSSOConfigStep: React.FC<
  StepProps & { finalizeNewSSOSetup: () => void; finalizeUpdateSSOSetup: () => void }
> = ({ state, dispatch, finalizeNewSSOSetup, finalizeUpdateSSOSetup, setIsOpen }) => {
  const handleFinalizeSSOSetup = async () => {
    dispatch({ type: 'SET_UPDATING', payload: true });
    setIsOpen(false);

    try {
      if (state.isNewClientCreated) {
        finalizeNewSSOSetup();
      } else {
        finalizeUpdateSSOSetup();
      }
    } finally {
      dispatch({ type: 'SET_UPDATING', payload: false });
    }
  };

  return (
    <>
      <h3 className="text-lg leading-6 font-semibold text-gray-900">Review SSO Configuration</h3>
      <div className="mt-2">
        <p className="text-sm text-gray-500">
          Please review the configuration details below to ensure accuracy before finalizing your SSO setup.
        </p>
        {state.isNewClientCreated && (
          <div className="mt-4">
            <p className="text-sm text-red-600">
              Press "Finalize SSO Setup" after reviewing to complete the process and fully add your SSO configured
              application.
            </p>
          </div>
        )}
        <ul className="mt-4 list-disc list-inside text-sm text-gray-600">
          <li style={{ overflow: 'auto', maxHeight: '200px' }} className="flex justify-between items-center">
            <div>
              <strong>Entity ID:</strong>{' '}
              {state.entityId || `urn:${state.application?.customUrl?.split('//')[1]?.replace(/\.com.*$/, '.com')}`}
            </div>
            <CopyButton
              text={
                state.entityId || `urn:${state.application?.customUrl?.split('//')[1]?.replace(/\.com.*$/, '.com')}`
              }
            />
          </li>
          <br />
          <li className="flex justify-between items-center">
            <div>
              <strong>Callback URL:</strong>{' '}
              {state.callbackUrl || state.formData.callbackUrl || state.application?.callbacks?.[0]}
            </div>
            <CopyButton
              text={state.callbackUrl || state.formData.callbackUrl || (state.application?.callbacks?.[0] as string)}
            />
          </li>
          <br />
          <li style={{ overflow: 'auto', maxHeight: '200px' }} className="flex justify-between items-center">
            <div>
              <strong>SAML Endpoint:</strong> {state.samlEndpoint || (state.application?.customUrl as string)}
            </div>
            <CopyButton text={state.samlEndpoint || (state.application?.customUrl as string)} />
          </li>
          <br />
          <li className="flex flex-col justify-between items-start overflow-auto max-h-200">
            <div className="flex justify-between items-center w-full">
              <strong>Signing Key Certificate:</strong>
              <button
                onClick={() => dispatch({ type: 'SET_FULL_CERT_SHOWN', payload: !state.isFullCertShown })}
                className="text-blue-500 underline"
              >
                {state.isFullCertShown ? 'Show Less' : 'Show More'}
              </button>
            </div>
            <div className="mt-2 w-full">
              {state.isFullCertShown ? (
                <span>{state.signingKeyCert}</span>
              ) : (
                <span>{state.signingKeyCert.substring(0, 155)}...</span>
              )}
            </div>
            <CopyButton text={state.signingKeyCert} className="mt-2" />
          </li>
        </ul>
      </div>
      <div className="mt-4">
        <p className="text-sm text-gray-500">
          After confirming, proceed to configure the SSO integration within your identity provider using these details.
          If you're using Auth0, this will involve logging into your Auth0 dashboard and setting up a new application
          with the provided Entity ID and Callback URL.
        </p>
      </div>
      {state.isUpdating ? (
        <div className="flex justify-center items-center h-12">
          <Spinner />
        </div>
      ) : (
        state.message.text && (
          <div className={`rounded-md p-4 ${state.message.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className={`h-5 w-5 ${state.message.type === 'error' ? 'text-red-400' : 'text-green-400'}`}
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className={`text-sm ${state.message.type === 'error' ? 'text-red-700' : 'text-green-700'}`}>
                  {state.message.text}
                </p>
              </div>
            </div>
          </div>
        )
      )}
      <div className="mt-4">
        <label htmlFor="newCallbackUrl" className="block text-sm font-semibold text-gray-700">
          Update Callback URL (Optional):
        </label>
        <span className="text-sm font-semibold text-center text-red-600">
          CAUTION: THIS SHOULD ONLY BE DONE IF YOU HAVE UPDATED AND CONFIRMED YOUR CONFIGURATION WITH YOUR SERVICE
          PROVIDER.
        </span>
        <input
          id="newCallbackUrl"
          type="text"
          value={state.formData.newCallbackUrl}
          onChange={e => dispatch({ type: 'SET_FORM_DATA', payload: { newCallbackUrl: e.target.value } })}
          className="my-6 block w-full p-2.0 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          placeholder="https://yourdomain.zendesk.com/sso/saml"
        />
      </div>
      <div className="flex justify-center items-center space-x-4">
        {!state.isNewClientCreated && (
          <button
            className="w-1/2 inline-flex justify-center px-4 py-2 text-sm font-medium text-black bg-gray-300 hover:bg-gray-300 rounded-md"
            onClick={() => {
              setIsOpen(false);
              dispatch({ type: 'CLOSE_MODAL' });
            }}
            disabled={state.isUpdating}
          >
            Close
          </button>
        )}
        <button
          className="w-1/2 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-seaBlue hover:bg-blue-700 rounded-md"
          onClick={handleFinalizeSSOSetup}
          disabled={state.isUpdating}
        >
          Finalize SSO Setup
        </button>
      </div>
    </>
  );
};

export default ReviewSSOConfigStep;
