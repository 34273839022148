import React, { useCallback, useState } from 'react';
import { addOrgAppByUrl, deleteOrgAppByUrl } from '../services';
import { captureException, captureMessage } from '@sentry/nextjs';
import { type UnifiedApplication } from '../interfaces/Client/Client';
import { type ApplicationAction, applicationActionTypes } from '../types/unifiedApplicationTypes';
import { fetchUrlClients } from '../services/AppCatalogAPI/appCatalogService';

export const useCustomApplications = (organizationId: string, dispatch: React.Dispatch<ApplicationAction>) => {
  const [optimisticState, setOptimisticState] = useState<UnifiedApplication[]>([]);

  const fetchApplications = useCallback(async () => {
    dispatch({ type: applicationActionTypes.FETCH_START });

    try {
      const urlClients = await fetchUrlClients(organizationId);

      setOptimisticState(urlClients);
      dispatch({ type: applicationActionTypes.SET_URL_CLIENTS, payload: urlClients });
    } catch (error) {
      captureMessage('Failed to fetch URL clients');
      captureException(error);
      dispatch({ type: applicationActionTypes.FETCH_ERROR, payload: error as Error });
    }
  }, [organizationId, dispatch]);

  const addApplication = useCallback(
    async (app: { url: string; names: string }) => {
      dispatch({ type: applicationActionTypes.FETCH_START });

      try {
        const newApp: UnifiedApplication = {
          url: app.url,
          name: app.names,
          type: 'urlClient',
          categories: [],
          uuid: '',
          enabled: false,
          sso: false,
          callbacks: [],
        };

        setOptimisticState(prevState => [...prevState, newApp]);
        dispatch({ type: 'ADD_CUSTOM_APPLICATION', payload: newApp });

        await addOrgAppByUrl(organizationId, [app.url], [app.names]);
        await fetchApplications();
      } catch (error) {
        console.error('Error adding URL application:', error);
        captureMessage('Failed to add URL application');
        captureException(error);
        dispatch({ type: applicationActionTypes.FETCH_ERROR, payload: error as Error });
        await fetchApplications(); // Reset to actual state on error
      }
    },
    [organizationId, dispatch, fetchApplications],
  );

  const deleteApplication = useCallback(
    async (url: string) => {
      dispatch({ type: applicationActionTypes.FETCH_START });

      const originalState = optimisticState;

      try {
        setOptimisticState(prevState => prevState.filter(app => app.url !== url));
        dispatch({ type: 'DELETE_CUSTOM_APPLICATION', payload: url });

        await deleteOrgAppByUrl(organizationId, url);
        await fetchApplications();
      } catch (error) {
        console.error('Error deleting URL application:', error);
        captureMessage('Failed to delete URL application');
        captureException(error);
        dispatch({ type: applicationActionTypes.FETCH_ERROR, payload: error as Error });
        setOptimisticState(originalState);
      }
    },
    [organizationId, dispatch, fetchApplications, optimisticState],
  );

  return {
    fetchUrlClients: fetchApplications,
    addApplication,
    deleteApplication,
    optimisticState,
  };
};
