import { captureException, captureMessage } from '@sentry/nextjs';
import axios from 'axios';

export type Category = {
  uuid: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateCategoryPayload = Pick<Category, 'name'>;
export type UpdateCategoryPayload = Partial<Pick<Category, 'name'>>;

export default {
  // Get all categories
  // GET /Category
  async getCategories(): Promise<Category[]> {
    try {
      const response = await axios.get(`/api/categories/list`);

      return response.data;
    } catch (error) {
      captureException(error);
      captureMessage('Failed to fetch categories');
      console.error('Failed to fetch categories:', error);
      throw error;
    }
  },

  // Get Category by uuid
  // GET /Category/:uuid
  async getCategory(id: string): Promise<Category> {
    const uuid = id.split('category#')[1];

    try {
      const response = await axios.get(`/api/categories/${uuid}`);

      return response.data;
    } catch (error) {
      captureException(error);
      captureMessage('Failed to fetch Category');
      console.error('Failed to fetch Category:', error);
      throw error;
    }
  },

  // Create Category
  // POST /Category
  async createCategory(payload: CreateCategoryPayload): Promise<Category> {
    try {
      const response = await axios.post(`/api/categories/create`, payload);

      return response.data;
    } catch (error) {
      captureException(error);
      captureMessage('Failed to create Category');
      console.error('Failed to create Category:', error);
      throw error;
    }
  },

  // Update Category
  // POST /Category/:uuid
  async updateCategory(id: string, payload: UpdateCategoryPayload): Promise<Category> {
    try {
      const uuid = id.split('category#')[1];
      const response = await axios.post(`/api/categories/${uuid}`, payload);

      return response.data;
    } catch (error) {
      captureException(error);
      captureMessage('Failed to update Category');
      console.error('Failed to update Category:', error);
      throw error;
    }
  },

  async deleteCategory(id: string): Promise<void> {
    const uuid = id.split('category#')[1];

    try {
      await axios.delete(`/api/categories/${uuid}`);
    } catch (error) {
      captureException(error);
      captureMessage('Failed to delete Category');
      console.error('Failed to delete Category:', error);
      throw error;
    }
  },
};
