import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Spinner } from '../../..';
import { type StepProps } from '../UpdateApplicationModalState';

const InitialStep: React.FC<StepProps> = ({ state, dispatch, sso, hasSSOCounterpart, setIsOpen }) => (
  <div>
    {state.isUpdating ? (
      <div>
        <h1 className="mb-4 text-lg leading-6 font-semibold text-gray-900">Updating Application...</h1>
        <div className="mb-20 flex items-center justify-center">
          <Spinner />
        </div>
      </div>
    ) : (
      <div>
        {state.showSuccessMessage ? (
          <div className="rounded-md bg-green-50 p-4 mt-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-green-700">{state.message.text}</p>
              </div>
            </div>
            <div className="mt-4 flex items-center justify-center">
              {' '}
              <button
                onClick={() => {
                  setIsOpen(false);
                  dispatch({ type: 'CLOSE_MODAL' });
                }}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sun sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h1 className="mb-4 text-lg leading-6 font-semibold text-gray-900">
                    Update Application or Configure SSO
                  </h1>
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Get Started
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Choose one of the options below to get started. You can update your application's access URL or
                      configure Single Sign-On (SSO) settings for a seamless user authentication experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse items-center justify-center">
              <button
                onClick={() => dispatch({ type: 'SET_CURRENT_STEP', payload: 'updateUrl' })}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-seaBlue text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-seaBlue sm:ml-3 sm:w-auto sm:text-sm"
              >
                Update Application URL
              </button>
              {sso && !hasSSOCounterpart && (
                <button
                  onClick={() => dispatch({ type: 'SET_CURRENT_STEP', payload: 'configureSSO' })}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sun text-base font-medium text-black hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sun sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Configure SSO
                </button>
              )}
              <button
                onClick={() => {
                  setIsOpen(false);
                  dispatch({ type: 'CLOSE_MODAL' });
                }}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sun sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Close
              </button>
            </div>
          </>
        )}
      </div>
    )}
  </div>
);

export default InitialStep;
