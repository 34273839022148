import { useCallback, useMemo, useState } from 'react';
import { type UnifiedApplication } from '../interfaces/Client/Client';
import { type Category } from '../services/CategoryService';

const useFilteredApplicationList = (
  urlClients: UnifiedApplication[] = [],
  selectedApplications: UnifiedApplication[] = [],
  catalogApplications: UnifiedApplication[] = [],
  topCategory?: Category,
  itemsPerPage = 12,
) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState({ title: '⬇ A-Z', value: 'name-asc' });
  const [categoryFilter, setCategoryFilter] = useState('');

  const filterApplications = useCallback(
    (applications: UnifiedApplication[]) => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      return applications.filter(application => {
        const matchesSearchTerm = (application.name || '').toLowerCase().includes(lowerCaseSearchTerm);
        const matchesCategory = categoryFilter === '' || application.categories.includes(categoryFilter);

        return matchesSearchTerm && matchesCategory;
      });
    },
    [searchTerm, categoryFilter],
  );

  const sortApplications = useCallback(
    (applications: UnifiedApplication[], isCatalog = false) => {
      return applications.sort((a, b) => {
        if (sortOption.value === 'name-asc') {
          return (a.name || '').localeCompare(b.name || '');
        }

        if (sortOption.value === 'name-desc') {
          return (b.name || '').localeCompare(a.name || '');
        }

        if (isCatalog && sortOption.value === 'top-asc' && topCategory) {
          // Sort by top category and then by name for catalog applications
          const aInTopCategory = a.categories.includes(topCategory.uuid);
          const bInTopCategory = b.categories.includes(topCategory.uuid);

          if (aInTopCategory && !bInTopCategory) {
            return -1;
          }

          if (!aInTopCategory && bInTopCategory) {
            return 1;
          }

          return (a.name || '').localeCompare(b.name || '');
        }

        return 0;
      });
    },
    [sortOption, topCategory],
  );

  const paginate = useCallback(
    (applications: UnifiedApplication[]) => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      return applications.slice(startIndex, endIndex);
    },
    [currentPage, itemsPerPage],
  );

  const filteredAndSortedUrlClients = useMemo(
    () => paginate(sortApplications(filterApplications(urlClients))),
    [filterApplications, sortApplications, paginate, urlClients],
  );

  const filteredAndSortedSelectedApplications = useMemo(
    () => paginate(sortApplications(filterApplications(selectedApplications))),
    [filterApplications, sortApplications, paginate, selectedApplications],
  );

  const filteredAndSortedCatalogApplications = useMemo(
    () => paginate(sortApplications(filterApplications(catalogApplications), true)),
    [filterApplications, sortApplications, paginate, catalogApplications],
  );

  const pageCountUrlClients = Math.ceil((filterApplications(urlClients).length || 0) / itemsPerPage);
  const pageCountSelectedApplications = Math.ceil(
    (filterApplications(selectedApplications).length || 0) / itemsPerPage,
  );
  const pageCountCatalogApplications = Math.ceil(
    (filterApplications(catalogApplications).length || 0) / itemsPerPage,
  );

  return {
    searchTerm,
    setSearchTerm,
    filteredAndSortedUrlClients,
    filteredAndSortedSelectedApplications,
    filteredAndSortedCatalogApplications,
    currentPage,
    setCurrentPage,
    sortOption,
    setSortOption,
    categoryFilter,
    setCategoryFilter,
    pageCountUrlClients,
    pageCountSelectedApplications,
    pageCountCatalogApplications,
  };
};

export default useFilteredApplicationList;
