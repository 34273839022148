import React from 'react';
import { Spinner } from '../..';
import { useApplicationsContext } from '../../../context/ApplicationsContext';
import ApplicationItem from '../../appCatalog/ApplicationItem';

const WorkspaceApplicationGridContent = (): JSX.Element => {
  const {
    optimisticState: { selectedApplications, customApplications },
  } = useApplicationsContext();

  const allApplications = [...selectedApplications, ...customApplications];

  return (
    <div className="grid grid-cols-6 gap-3 px-4 py-8 sm:px-0 min-h-full" style={{ flexGrow: 1 }}>
      {allApplications
        ?.sort((a, b) => {
          return a?.name?.localeCompare(b.name as string) || 0;
        })
        .map((app, index) => <ApplicationItem key={index} application={app} />)}
    </div>
  );
};

const WorkspaceApplicationGrid = (): JSX.Element => {
  const { isFetching, isInitialFetchComplete } = useApplicationsContext();

  if (!isInitialFetchComplete || isFetching) {
    return (
      <div className="flex items-center justify-center w-full mx-auto col-span-6">
        <Spinner />
      </div>
    );
  }

  return <WorkspaceApplicationGridContent />;
};

export default WorkspaceApplicationGrid;
