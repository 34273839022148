import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Spinner } from '../../..';
import { type UnifiedApplication } from '../../../../interfaces/Client/Client';
import { type StepProps } from '../UpdateApplicationModalState';

interface ConfigureSSOStepProps extends StepProps {
  handleSSOConfiguration: () => void;
  fetchUnifiedApplications: () => Promise<UnifiedApplication[]>;
}

const ConfigureSSOStep: React.FC<ConfigureSSOStepProps> = ({
  state,
  dispatch,
  handleSSOConfiguration,
  fetchUnifiedApplications,
}) => (
  <>
    <div className="text-sm mb-4">
      Setting up Single Sign-On (SSO) allows users to access multiple services with one set of credentials, enhancing
      both security and convenience. You'll need to provide the 'Callback URL', a crucial component in the SSO
      framework.
    </div>
    <div className="mt-4">
      <label htmlFor="callbackUrl" className="block text-sm font-medium text-gray-700">
        Callback URL
      </label>
      <input
        id="callbackUrl"
        type="text"
        value={state.formData.callbackUrl}
        onChange={e => dispatch({ type: 'SET_FORM_DATA', payload: { callbackUrl: e.target.value } })}
        className="mt-1 block w-full p-2.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
        placeholder={`e.g. ${state.application?.ssoUrlExample ?? 'https://yourdomain.example.com/sso/saml'}`}
      />
      <p className="mt-1 text-xs text-gray-500">
        This URL is where the SSO provider sends the user after authentication. It must be secure and correctly
        configured in your SSO settings. <strong>Note: Callback Url format may vary based on your SSO provider.</strong>
      </p>
    </div>
    <div className="flex justify-between items-center my-6">
      {state.isUpdating ? (
        <Spinner />
      ) : (
        <>
          <button
            className="w-auto h-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => dispatch({ type: 'SET_CURRENT_STEP', payload: 'initial' })}
          >
            <div className="flex items-center">Back to Initial</div>
          </button>
          <button
            onClick={handleSSOConfiguration}
            disabled={state.isUpdating}
            className={`w-auto h-auto inline-flex justify-center px-4 py-2 text-sm font-medium text-white ${state.isUpdating ? 'bg-gray-400' : 'bg-seaBlue hover:bg-blue-700'} rounded-md`}
          >
            {state.isUpdating ? 'Enabling SSO Configuration...' : 'Enable SSO'}
          </button>
        </>
      )}
    </div>
    {state.message.type === 'error' && (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-red-700">{state.message.text}</p>
            <button
              onClick={async () => {
                dispatch({ type: 'SET_MESSAGE', payload: { text: '', type: 'info' } });
                await fetchUnifiedApplications();
              }}
              className={`w-auto h-auto inline-flex justify-center px-4 py-2 text-sm font-medium text-white ${state.isUpdating ? 'bg-gray-400' : 'bg-seaBlue hover:bg-blue-700'} rounded-md`}
            >
              Refresh Page
            </button>
          </div>
        </div>
      </div>
    )}
  </>
);

export default ConfigureSSOStep;
