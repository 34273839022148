import React from 'react';
import SelectedApplicationsSection from './SelectedApplicationsSection';
import AppCatalogSection from './AppCatalogSection';
import CustomApplicationsSection from './CustomApplicationsSection';

const ApplicationSections: React.FC = () => (
  <>
    <SelectedApplicationsSection />
    <AppCatalogSection />
    <CustomApplicationsSection />
  </>
);

export default ApplicationSections;
