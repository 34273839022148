import React, { useState } from 'react';
import { type UnifiedApplication } from '../../interfaces/Client/Client';
import { useUser } from '@auth0/nextjs-auth0/client';
import UpdateApplicationModal from './Modal/UpdateApplicationModal';
import DeleteSSOApplicationModal from './DeleteSSOApplicationModal';

interface ApplicationItemProps {
  application: UnifiedApplication;
  isRemovable?: boolean;
  organizationId?: string;
  hasSSOCounterpart?: boolean;
  actionLoading: boolean;
  onToggle: (application: UnifiedApplication) => Promise<void>;
  handleDeleteSSOClient: (clientId: string) => Promise<void>;
}

const SelectedApplicationItem: React.FC<ApplicationItemProps> = ({
  application,
  organizationId,
  hasSSOCounterpart,
  onToggle,
  handleDeleteSSOClient,
}) => {
  const [isUpdateUrlModalOpen, setIsUpdateUrlModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const { user } = useUser();

  const handleToggle = async () => {
    setLocalLoading(true);
    setTimeout(() => setLocalLoading(false), 2000);

    if (application.customUrl?.includes('samlp')) {
      setIsConfirmationModalOpen(true);
    } else {
      await onToggle(application);
    }
  };

  // Validate the application logo path or use the default logo path
  const logoPath = application.logo || '/assets/cubeless_white_logo.svg';

  return (
    <div className="flex flex-col items-center justify-between pt-4 bg-white rounded-lg shadow border border-gray-200 space-y-3 max-w-xs transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
      <div className="w-full flex justify-center pb-4">
        <img
          src={logoPath}
          alt={`${application.name} Logo`}
          width={80}
          height={80}
          className="rounded-md aspect-square"
        />
      </div>
      <div className="text-center flex flex-col items-center">
        <a
          href={(application.customUrl as string) || '#'}
          target="_blank"
          rel="noopener noreferrer"
          className="text-md font-medium text-gray-800"
        >
          {application.name as string}
        </a>
        {application.type === 'ssoEnabled' && (
          <div className="m-1 inline-flex items-center rounded-md py-1 px-2 text-xs font-medium bg-gray-200 text-gray-900">
            SSO
          </div>
        )}
      </div>
      <div className="flex w-full border-t border-gray-200">
        <button
          onClick={handleToggle}
          className="w-1/2 border-r border-gray-200 text-red-700 py-2 text-sm font-medium hover:bg-gray-100 focus:outline-none"
        >
          {localLoading ? (
            <div className="flex flex-col items-center justify-center">
              <svg
                className="animate-spin h-4 w-4 text-red-700"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              <span>{application.type === 'ssoEnabled' ? 'Deleting...' : 'Disabling...'}</span>
            </div>
          ) : application.enabled ? (
            application.customUrl?.includes('samlp') ? (
              'Delete'
            ) : (
              'Disable'
            )
          ) : (
            'Enable'
          )}
        </button>
        <button
          onClick={() => setIsUpdateUrlModalOpen(true)}
          className="w-1/2 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 focus:outline-none"
        >
          {application.type === 'ssoEnabled' ? 'Review' : 'Edit'}
        </button>
      </div>
      <UpdateApplicationModal
        application={application}
        isOpen={isUpdateUrlModalOpen}
        setIsOpen={setIsUpdateUrlModalOpen}
        organizationId={organizationId || ''}
        userSub={user?.sub || ''}
        sso={application.sso}
        hasSSOCounterpart={hasSSOCounterpart}
      />
      <DeleteSSOApplicationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        message={
          <>
            Are you sure you want to delete the SSO configured application <br /> <strong>{application.name}</strong>?
            If so, please type in the name of the application below.
          </>
        }
        applicationName={application.name as string}
        clientId={application.uuid}
        handleDeleteSSOClient={() => handleDeleteSSOClient(application.uuid)}
      />
    </div>
  );
};

export default SelectedApplicationItem;
