// reducers/applicationReducer.ts
import {
  type ApplicationAction,
  applicationActionTypes,
  type ApplicationState,
} from '../types/unifiedApplicationTypes';

export const applicationReducer = (state: ApplicationState, action: ApplicationAction): ApplicationState => {
  switch (action.type) {
    case applicationActionTypes.FETCH_START:
      return { ...state, isLoading: true, error: null };
    case applicationActionTypes.FETCH_SUCCESS:
      if (
        typeof action.payload === 'object' &&
        action.payload !== null &&
        'catalogApplications' in action.payload &&
        'selectedApplications' in action.payload
      ) {
        return {
          ...state,
          catalogApplications: action.payload.catalogApplications || [],
          selectedApplications: action.payload.selectedApplications || [],
          isLoading: false,
        };
      }

      return state;
    case applicationActionTypes.FETCH_COMPLETE:
      return {
        ...state,
        isInitialFetchComplete: true,
        isLoading: false,
      };
    case applicationActionTypes.FETCH_ERROR:
      return { ...state, isLoading: false, error: action.payload } as ApplicationState;
    case applicationActionTypes.SET_ORGANIZATION_ID:
      return { ...state, organizationId: action.payload } as ApplicationState;
    case applicationActionTypes.SET_URL_CLIENTS:
      return { ...state, customApplications: action.payload } as ApplicationState;
    case applicationActionTypes.SET_SELECTED_APPLICATIONS:
      return { ...state, fullSelectedApplications: action.payload } as ApplicationState;
    case applicationActionTypes.SET_CATEGORIES:
      return { ...state, categories: action.payload } as ApplicationState;
    case applicationActionTypes.SET_LOADING:
      return { ...state, isLoading: action.payload } as ApplicationState;
    case applicationActionTypes.SET_SORT_OPTION:
    case applicationActionTypes.SET_PAGE:
      if (typeof action.payload === 'object' && action.payload !== null && 'key' in action.payload) {
        return { ...state, [action.payload.key]: action.payload.value };
      }

      return state;
    case applicationActionTypes.TOGGLE_SELECTED_APPLICATION:
      return {
        ...state,
        selectedApplications: state.selectedApplications.map(app =>
          app.uuid === action.payload ? { ...app, isSelected: !app.enabled } : app,
        ),
      };
    case applicationActionTypes.SET_CATEGORY_FILTER:
      return { ...state, categoryFilter: action.payload } as ApplicationState;
    case 'ADD_CUSTOM_APPLICATION':
      return { ...state, customApplications: [...state.customApplications, action.payload] } as ApplicationState;
    case 'DELETE_CUSTOM_APPLICATION':
      return { ...state, customApplications: state.customApplications.filter(app => app.url !== action.payload) };
    default:
      return state;
  }
};
