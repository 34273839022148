import { Disclosure, Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { capitalizeFirstLetter, capitalizeWords } from '../../../utils';
import { useRouter } from 'next/router';
import { type UserProfile } from '@auth0/nextjs-auth0/client';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function UserNavbar({
  getTheUserRole,
  userInfo,
  organizationInfo = {
    workspaceLogo: '',
    orgName: '',
  },
}: {
  getTheUserRole: string;
  userInfo: UserProfile;
  organizationInfo?: { workspaceLogo: string; orgName: string };
}) {
  const { workspaceLogo = '', orgName = '' } = organizationInfo;
  const router = useRouter();

  const signOutAuth0 = async () => {
    try {
      await router.push('/api/auth/logout');
    } catch (error) {
      console.error(error);
    }
  };

  const getNavigationItems = () => {
    const baseNavigation = [
      { name: 'Workspace', href: '/cubeless-secure-workspace', current: false },
      { name: 'Profile', href: '/user-settings/userProfile', current: false },
    ];

    const adminNavigation = [];

    if (getTheUserRole === 'admin' || getTheUserRole === 'superAdmin') {
      adminNavigation.push({ name: 'Admin Dashboard', href: '/admin-home', current: false });
    }

    if (getTheUserRole === 'superAdmin') {
      adminNavigation.push({ name: 'Cubeless Dashboard', href: '/super-admin', current: false });
    }

    const signOutNavigation = [
      { name: 'Sign out', href: '/api/auth/logout', current: false, onClick: () => signOutAuth0() },
    ];

    return [...baseNavigation, ...adminNavigation, ...signOutNavigation];
  };

  const navigationItems = getNavigationItems();

  return (
    <div>
      <Disclosure as="nav" className="bg-white shadow-lg" style={{ zIndex: 100, position: 'relative' }}>
        {({ open }) => (
          <>
            <div className="mx-auto sm:px-6 lg:px-8">
              <div className="border-gray-700">
                <div className="flex items-center justify-between px-4 sm:px-0">
                  <div className="flex-1">
                    <div className="h-0 flex flex-shrink-0 items-center px-2">
                      <div className="relative h-10 w-auto">
                        <Image
                          className="h-full w-auto"
                          src="/cubeless-logo.png"
                          width={110}
                          height={40}
                          alt="Cubeless"
                        />
                      </div>
                      <div className="flex items-center px-[30px]">
                        <Image
                          src="/assets/vertical-dash.png"
                          alt="seperator"
                          className="h-41 w-auto"
                          width={40}
                          height={40}
                        />
                      </div>
                      <div className="md:text-md 2xl:text-md">
                        {workspaceLogo ? (
                          <Image
                            src={workspaceLogo || ''}
                            alt={capitalizeWords(orgName || '')}
                            className="h-41 w-auto"
                            width={60}
                            height={60}
                          />
                        ) : (
                          <p className="text-lg font-semibold py-5 w-95 text-[#2B3D45] md:text-md 2xl:text-md">
                            {capitalizeWords(orgName || '')}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center" />
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <div className="hidden md:block">
                        <div className="ml-4 flex items-baseline space-x-4">
                          {getTheUserRole === 'superAdmin' && (
                            <span className="block px-4 py-2 text-md font-medium">{publicRuntimeConfig.VERSION}</span>
                          )}
                          <div className="text-lg font-semibold py-5 w-95 text-[#2B3D45] md:text-md 2xl:text-md">
                            Hello, {userInfo && capitalizeFirstLetter(userInfo?.nickname as string)}
                          </div>
                        </div>
                      </div>
                      {/* Profile dropdown on Desktop Navigation */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            {userInfo?.picture && userInfo.picture !== '' ? (
                              <img
                                className="h-10 w-10 rounded-full"
                                src={userInfo.picture}
                                width={30}
                                height={30}
                                alt="Profile"
                              />
                            ) : (
                              <Image
                                className="h-10 w-10 rounded-full"
                                src="/favicon.ico"
                                width={30}
                                height={30}
                                alt="Cubeless Logo"
                              />
                            )}
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {navigationItems.map((item, index) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-50' : 'bg-white',
                                      'block px-4 py-2 text-md font-medium text-gray-700',
                                      index !== navigationItems.length - 1 ? 'border-b border-gray-200' : '',
                                      'hover:bg-seaBlue hover:text-white hover:font-medium hover:border-b hover:border-seaBlue hover:ring-1 hover:ring-seaBlue hover:ring-opacity-100',
                                    )}
                                    onClick={item.name === 'Sign out' ? () => signOutAuth0() : undefined}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  {/* Close Mobile Drop Down View Button */}
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>
            {/*Mobile Pop-up Navigation when screen is sm to xs */}
            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                {navigationItems.map(item => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium',
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-700 pt-4 pb-3">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    {userInfo?.picture && userInfo.picture !== '' ? (
                      <img className="h-10 w-10 rounded-full" src={userInfo.picture} alt="Profile" />
                    ) : (
                      <Image
                        className="h-10 w-10 rounded-full"
                        src="/favicon.ico"
                        width={30}
                        height={30}
                        alt="Cubeless Logo"
                      />
                    )}
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">{userInfo.name}</div>
                    <div className="text-sm font-medium leading-none text-gray-400">{userInfo.email}</div>
                  </div>
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                  />
                </div>
                {/* userNavigation when in sm - xs screen size */}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
