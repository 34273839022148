import React, { createContext, type ReactNode, useContext, useEffect, useMemo, useReducer } from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import { getOrganizationByUserId } from '../services';
import { useCategories } from '../hooks/useCategories';
import { useCustomApplications } from '../hooks/useCustomApplications';
import useUnifiedApplications from '../hooks/useUnifiedApplications';
import { applicationReducer } from '../reducers/applicationReducer';
import {
  type ApplicationAction,
  applicationActionTypes,
  type ApplicationState,
  initialApplicationState,
} from '../types/unifiedApplicationTypes';
import { type OrganizationData } from '../interfaces/Organization/Organization';
import { type UnifiedApplication, type UpdateOrganizationApplicationDto } from '../interfaces/Client/Client';
import type { Category } from '../services/CategoryService';

const ApplicationsContext = createContext<
  | (ApplicationState & {
    addCustomApplication: (app: { url: string; names: string }) => Promise<void>;
    deleteCustomApplication: (url: string) => Promise<void>;
    createApplication: (app: UnifiedApplication) => Promise<void>;
    updateApplication: (applicationId: string, applicationData: UpdateOrganizationApplicationDto) => Promise<void>;
    deleteApplication: (app: UnifiedApplication) => Promise<void>;
    enableApplication: (app: UnifiedApplication) => Promise<void>;
    handleEnableCatalogApp: (app: UnifiedApplication) => Promise<void>;
    handleSelectedToggleStatus: (app: UnifiedApplication) => Promise<void>;
    handleDeleteSSOClient: (clientId: string) => Promise<void>;
    fetchUrlClients: () => Promise<UnifiedApplication[]>;
    fetchUnifiedApplications: () => Promise<UnifiedApplication[]>;
    configureSSO: (
      userSub: string,
      application: UnifiedApplication,
      callbackUrl: string,
    ) => Promise<{ samlEndpoint: string; signingKey: string; entityId: string }>;
    updateSSOClientCallbacks: (clientId: string, newCallbackUrl: string) => Promise<void>;
    isFetching: boolean;
    actionLoading: { [key: string]: boolean };
    optimisticState: {
      catalogApplications: UnifiedApplication[];
      selectedApplications: UnifiedApplication[];
      customApplications: UnifiedApplication[];
    };
    isInitialFetchComplete: boolean;
    topCategory?: Category;
  })
  | undefined
>(undefined);

export const ApplicationsProvider = ({ children }: { children: ReactNode }) => {
  const { user, isLoading: isUserLoading } = useUser();
  const userId = user?.sub || '';
  const [state, dispatch] = useReducer<React.Reducer<ApplicationState, ApplicationAction>>(
    applicationReducer,
    initialApplicationState,
  );
  const { categories, getCategories, topCategory } = useCategories();
  const customApplications = useCustomApplications(state.organizationId as string, dispatch);
  const unifiedApplications = useUnifiedApplications(state.organizationId as string, dispatch);

  useEffect(() => {
    if (!isUserLoading && userId) {
      getOrganizationByUserId(userId).then((orgData: OrganizationData) => {
        if (orgData && orgData.organization) {
          dispatch({ type: applicationActionTypes.SET_ORGANIZATION_ID, payload: orgData.organization.organization_id });
        } else {
          dispatch({ type: applicationActionTypes.SET_ORGANIZATION_ID, payload: null });
        }
      });
    }
  }, [userId, isUserLoading]);

  useEffect(() => {
    const fetchApplications = async () => {
      if (state.organizationId) {
        dispatch({ type: applicationActionTypes.FETCH_START });

        try {
          await Promise.all([customApplications.fetchUrlClients(), unifiedApplications.fetchApplications()]);

          dispatch({ type: applicationActionTypes.FETCH_COMPLETE });
        } catch (error) {
          console.error('Error fetching applications in ApplicationsProvider:', error);
          dispatch({ type: applicationActionTypes.FETCH_ERROR, payload: error as Error });
        }
      }
    };

    fetchApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.organizationId, customApplications.fetchUrlClients, unifiedApplications.fetchApplications]);

  useEffect(() => {
    getCategories()
      .then(cats => {
        dispatch({ type: applicationActionTypes.SET_CATEGORIES, payload: cats ?? [] });
      })
      .catch(error => {
        dispatch({ type: applicationActionTypes.FETCH_ERROR, payload: error as Error });
      });
  }, [getCategories]);

  const value = useMemo(
    () => ({
      ...state,
      dispatch,
      addCustomApplication: customApplications.addApplication,
      deleteCustomApplication: customApplications.deleteApplication,
      createApplication: unifiedApplications.createApplication,
      updateApplication: unifiedApplications.updateApplication,
      deleteApplication: unifiedApplications.deleteApplication,
      enableApplication: unifiedApplications.enableApplication,
      handleEnableCatalogApp: unifiedApplications.handleEnableCatalogApp,
      handleSelectedToggleStatus: unifiedApplications.handleSelectedToggleStatus,
      handleDeleteSSOClient: unifiedApplications.handleDeleteSSOClient,
      fetchUnifiedApplications: unifiedApplications.fetchApplications,
      fetchUrlClients: customApplications.fetchUrlClients,
      configureSSO: unifiedApplications.configureSSO,
      updateSSOClientCallbacks: unifiedApplications.updateSSOClientCallbacks,
      isFetching: unifiedApplications.isFetching || state.isLoading,
      actionLoading: unifiedApplications.actionLoading,
      optimisticState: {
        catalogApplications: unifiedApplications.optimisticState.catalogApplications,
        selectedApplications: unifiedApplications.optimisticState.selectedApplications,
        customApplications: customApplications.optimisticState,
      },
      isInitialFetchComplete: state.isInitialFetchComplete,
      categories,
      topCategory,
    }),
    [
      state,
      customApplications.addApplication,
      customApplications.deleteApplication,
      customApplications.fetchUrlClients,
      customApplications.optimisticState,
      unifiedApplications.createApplication,
      unifiedApplications.updateApplication,
      unifiedApplications.deleteApplication,
      unifiedApplications.enableApplication,
      unifiedApplications.handleEnableCatalogApp,
      unifiedApplications.handleSelectedToggleStatus,
      unifiedApplications.handleDeleteSSOClient,
      unifiedApplications.fetchApplications,
      unifiedApplications.configureSSO,
      unifiedApplications.updateSSOClientCallbacks,
      unifiedApplications.isFetching,
      unifiedApplications.actionLoading,
      unifiedApplications.optimisticState.catalogApplications,
      unifiedApplications.optimisticState.selectedApplications,
      categories,
      topCategory,
    ],
  );

  //@ts-ignore
  return <ApplicationsContext.Provider value={value}>{children}</ApplicationsContext.Provider>;
};

export const useApplicationsContext = () => {
  const context = useContext(ApplicationsContext);

  if (context === undefined) {
    throw new Error('useApplicationsContext must be used within an ApplicationsProvider');
  }

  return context;
};

export { ApplicationsContext };
