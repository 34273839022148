import { type UnifiedApplication } from '../interfaces/Client/Client';
import { type Dispatch } from 'react';
import type { Category } from '../services/CategoryService';

export interface ApplicationState {
    applications: UnifiedApplication[];
    catalogApplications: UnifiedApplication[];
    selectedApplications: UnifiedApplication[];
    displayedApplications: UnifiedApplication[];
    filteredCatalogApplications: UnifiedApplication[];
    isHandlingApplicationAction: boolean;
    isLoading: boolean;
    error: Error | null;
    organizationId: string | null;
    fullUrlClients: UnifiedApplication[];
    fullSelectedApplications: UnifiedApplication[];
    categories: Category[];
    categoryFilter: string;
    customApplications: UnifiedApplication[];
    dispatch?: Dispatch<ApplicationAction>;
    isInitialFetchComplete: boolean;
}

export interface ApplicationAction {
    type: string;
    payload?:
    | Partial<ApplicationState>
    | UnifiedApplication
    | Error
    | { catalogApplications: UnifiedApplication[]; selectedApplications: UnifiedApplication[] }
    | string
    | boolean
    | UnifiedApplication[]
    | { key: string; value: unknown }
    | null
    | void
    | Category[];
}

export const initialApplicationState: Omit<ApplicationState, 'dispatch'> = {
    applications: [],
    catalogApplications: [],
    selectedApplications: [],
    displayedApplications: [],
    filteredCatalogApplications: [],
    isHandlingApplicationAction: false,
    isLoading: false,
    error: null,
    organizationId: null,
    fullUrlClients: [],
    fullSelectedApplications: [],
    categories: [],
    categoryFilter: '',
    customApplications: [],
    isInitialFetchComplete: false,
};

export const applicationActionTypes = {
    FETCH_START: 'FETCH_START',
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    FETCH_COMPLETE: 'FETCH_COMPLETE',
    FETCH_ERROR: 'FETCH_ERROR',
    SET_ORGANIZATION_ID: 'SET_ORGANIZATION_ID',
    SET_URL_CLIENTS: 'SET_URL_CLIENTS',
    SET_SELECTED_APPLICATIONS: 'SET_SELECTED_APPLICATIONS',
    SET_CATEGORIES: 'SET_CATEGORIES',
    SET_LOADING: 'SET_LOADING',
    SET_SORT_OPTION: 'SET_SORT_OPTION',
    SET_PAGE: 'SET_PAGE',
    SET_CATEGORY_FILTER: 'SET_CATEGORY_FILTER',
    TOGGLE_SELECTED_APPLICATION: 'TOGGLE_SELECTED_APPLICATION',
};
