// hooks/useUnifiedApplications.ts
import React, { useCallback, useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import {
  type CreateOrganizationApplicationDto,
  type UnifiedApplication,
  type UpdateOrganizationApplicationDto,
} from '../interfaces/Client/Client';
import {
  createClientForOrganization,
  createOrganizationApplication,
  deleteOrganizationApplication,
  deleteSSOClient,
  getOrganizationByUserId,
  updateOrganizationApplication,
  updateSSOClient,
  updateSSOClientCallbacks,
  fetchCatalogApplications,
  fetchOrganizationApplications,
  fetchSSOEnabledClients,
  mergeApplications,
  transformSSOEnabledClients,
} from '../services';
import { type ApplicationAction, applicationActionTypes } from '../types/unifiedApplicationTypes';
import { type OrganizationData } from '../interfaces/Organization/Organization';

type OptimisticState = {
  catalogApplications: UnifiedApplication[];
  selectedApplications: UnifiedApplication[];
};

type ActionLoadingState = { [key: string]: boolean };

export const useUnifiedApplications = (organizationId: string, dispatch: React.Dispatch<ApplicationAction>) => {
  const [optimisticState, setOptimisticState] = useState<OptimisticState>({
    catalogApplications: [],
    selectedApplications: [],
  });
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState<ActionLoadingState>({});

  const fetchApplications = useCallback(async (): Promise<UnifiedApplication[]> => {
    setIsFetching(true);
    dispatch({ type: applicationActionTypes.FETCH_START });

    try {
      const [catalogApps, orgApps, ssoClients] = await Promise.all([
        fetchCatalogApplications(),
        fetchOrganizationApplications(organizationId),
        fetchSSOEnabledClients(organizationId).catch(() => []), // Default to empty array on failure
      ]);

      const ssoEnabledApps = transformSSOEnabledClients(ssoClients);

      const { catalogApplications, selectedApplications } = mergeApplications(catalogApps, orgApps, ssoEnabledApps);

      setOptimisticState({ catalogApplications, selectedApplications });
      dispatch({ type: applicationActionTypes.FETCH_SUCCESS, payload: { catalogApplications, selectedApplications } });

      return selectedApplications;
    } catch (error) {
      captureException(error);
      dispatch({ type: applicationActionTypes.FETCH_ERROR, payload: error as Error });
      throw error;
    } finally {
      setIsFetching(false);
    }
  }, [organizationId, dispatch]);

  useEffect(() => {
    if (organizationId) {
      fetchApplications();
    }
  }, [organizationId, fetchApplications]);

  const optimisticallyUpdateApplication = (
    applicationId: string,
    updateFn: (app: UnifiedApplication) => UnifiedApplication,
  ) => {
    setOptimisticState(prevState => ({
      ...prevState,
      selectedApplications: prevState.selectedApplications.map(app =>
        app.uuid === applicationId ? updateFn(app) : app,
      ),
    }));
  };

  const performActionWithOptimisticUpdate = async (
    applicationId: string,
    updateFn: (app: UnifiedApplication) => UnifiedApplication,
    actionFn: () => Promise<void>,
  ) => {
    setActionLoading(prev => ({ ...prev, [applicationId]: true }));
    optimisticallyUpdateApplication(applicationId, updateFn);

    try {
      await actionFn();
      await fetchApplications();
    } catch (error) {
      captureException(error);
      await fetchApplications(); // Reset to actual state on error
    } finally {
      setActionLoading(prev => ({ ...prev, [applicationId]: false }));
    }
  };

  const createApplication = async (applicationData: CreateOrganizationApplicationDto) => {
    if (!applicationData.organizationId) {
      throw new Error('Organization ID is missing');
    }

    try {
      await createOrganizationApplication(applicationData);
      await fetchApplications();
    } catch (error) {
      captureException(error);
      console.error('Error creating application:', error);
    }
  };

  const updateApplicationStatus = async (applicationId: string, applicationData: UpdateOrganizationApplicationDto) => {
    await performActionWithOptimisticUpdate(
      applicationId,
      app => ({ ...app, enabled: applicationData.enabled }),
      async () => {
        await updateOrganizationApplication(organizationId, applicationId, applicationData);
      },
    );
  };

  const updateApplication = async (applicationId: string, applicationData: UpdateOrganizationApplicationDto) => {
    await performActionWithOptimisticUpdate(
      applicationId,
      app => ({ ...app, ...applicationData }),
      async () => {
        await updateOrganizationApplication(organizationId, applicationId, applicationData);
      },
    );
  };

  const deleteApplication = async (applicationId: string) => {
    await performActionWithOptimisticUpdate(
      applicationId,
      app => app,
      async () => {
        await deleteOrganizationApplication(organizationId, applicationId);
      },
    );
  };

  const enableApplication = async (application: UnifiedApplication) => {
    await updateApplicationStatus(application.uuid, {
      organizationId,
      applicationId: application.uuid,
      enabled: !application.enabled,
    });
  };

  const handleEnableCatalogApp = async (application: UnifiedApplication) => {
    await performActionWithOptimisticUpdate(
      application.uuid,
      app => ({ ...app, enabled: true }),
      async () => {
        const orgApps = await fetchOrganizationApplications(organizationId);
        const matchingSelectedApp = orgApps.find(
          (app: { applicationId: string }) => app.applicationId === application.uuid,
        );

        if (matchingSelectedApp) {
          await updateApplicationStatus(matchingSelectedApp.applicationId, {
            organizationId,
            applicationId: application.uuid,
            enabled: true,
          });
        } else {
          await createApplication({ organizationId, applicationId: application.uuid, enabled: true });
        }
      },
    );
  };

  const handleSelectedToggleStatus = async (application: UnifiedApplication) => {
    const appId = application.applicationId || application.uuid;

    if (appId) {
      if (application.type === 'organization') {
        await updateApplicationStatus(appId, { organizationId, applicationId: appId, enabled: !application.enabled });
      } else if (application.type === 'ssoEnabled') {
        await performActionWithOptimisticUpdate(
          appId,
          app => app,
          async () => {
            await deleteSSOClient(appId);
          },
        );
      } else {
        console.error('Unknown application type:', application.type);
      }
    } else {
      console.error('applicationId or uuid is not available:', application);
    }
  };

  const handleDeleteSSOClient = async (clientId: string) => {
    await performActionWithOptimisticUpdate(
      clientId,
      app => app,
      async () => {
        await deleteSSOClient(clientId);
      },
    );
  };

  const configureSSO = async (
    userSub: string,
    application: UnifiedApplication,
    callbackUrl: string,
  ): Promise<{ samlEndpoint: string; signingKey: string; entityId: string }> => {
    const organizationData: OrganizationData = await getOrganizationByUserId(userSub);

    if (organizationData && organizationData.organization) {
      const clientName = `${organizationData.organization.name}-${application.name}`;

      const createResponse = await createClientForOrganization(clientName, true);

      if (
        !createResponse ||
        !createResponse.client_id ||
        !createResponse.signing_keys ||
        !createResponse.signing_keys[0]
      ) {
        throw new Error('createClientForOrganization did not return a valid response');
      }

      const signingKey = createResponse.signing_keys[0].cert as string;

      const clientId = createResponse.client_id as string;

      const auth0IssuerBaseUrl = process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL as string;
      const samlEndpoint = `${auth0IssuerBaseUrl}/samlp/${clientId}`;

      let entityId = '';

      if (auth0IssuerBaseUrl !== undefined) {
        entityId = `urn:${auth0IssuerBaseUrl.split('//')[1]}`;
      } else {
        console.error('auth0IssuerBaseUrl is undefined');
      }

      try {
        await updateSSOClient(clientId, samlEndpoint, entityId, callbackUrl, application.logo as string);

        return { samlEndpoint, signingKey, entityId };
      } catch (error) {
        console.error('Error during SSO configuration:', error);
        await deleteSSOClient(clientId); // Delete the SSO client if the updateSSOClient call fails
        throw error;
      }
    } else {
      throw new Error('Organization data not found');
    }
  };

  return {
    fetchApplications,
    createApplication,
    updateApplicationStatus,
    updateApplication,
    deleteApplication,
    enableApplication,
    handleEnableCatalogApp,
    handleSelectedToggleStatus,
    handleDeleteSSOClient,
    configureSSO,
    updateSSOClientCallbacks,
    isFetching,
    actionLoading,
    optimisticState,
  };
};

export default useUnifiedApplications;
