import { Fragment } from 'react';
import { useRouter } from 'next/router';
import { Menu, Transition } from '@headlessui/react';
import { useUserContext } from '../../../context/UserContext';
import Image from 'next/image';

const userNavigation = [
  { name: 'Workspace', href: '/cubeless-secure-workspace' },
  { name: 'Profile', href: '/user-settings/userProfile' },
  { name: 'Admin Dashboard', href: '/admin-home' },
  { name: 'Cubeless Dashboard', href: '/super-admin' },
  { name: 'Invite Admin', href: '/super-admin/inviteAdmin' },
  { name: 'Manage Catalog', href: '/super-admin/catalog' },
  { name: 'Sign out', href: '#' },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function UserProfileDropdown() {
  const router = useRouter();
  const { user } = useUserContext();

  const logout = async () => {
    try {
      await router.push('/api/auth/logout');
    } catch (error) {
      console.error(error);
    }
  };

  if (!user) {
    router.push('/');
  }

  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <span className="sr-only">Admin User Last Name</span>
          {user?.picture && user.picture !== '' ? (
            <img
              className="h-10 w-10 rounded-full"
              src={user.picture}
              width={30}
              height={30}
              alt="Profile"
            />
          ) : (
            <Image
              className="h-10 w-10 rounded-full"
              src="/favicon.ico"
              width={30}
              height={30}
              alt="Cubeless Logo"
            />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation.map((item, index) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <a
                  href={item.href}
                  className={classNames(
                    active ? 'bg-gray-50' : 'bg-white',
                    'block px-4 py-2 text-md font-medium text-gray-700',
                    index !== userNavigation.length - 1 ? 'border-b border-gray-200' : '',
                    'hover:bg-seaBlue hover:text-white hover:font-medium hover:border-b hover:border-seaBlue hover:ring-1 hover:ring-seaBlue hover:ring-opacity-100',
                  )}
                  onClick={item.name === 'Sign out' ? () => logout() : undefined}
                >
                  {item.name}
                </a>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
