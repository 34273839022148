import React from 'react';
import Image from 'next/image';
import styles from '../../../styles/Navbar.module.css';
import { Auth0LoginButton, Auth0SignUpButton } from '../..';

const LandingNavbar = () => {
  return (
    <div className={styles.navbar}>
      <div className={styles.logo}>
        <div className="relative h-10 w-auto">
          <Image
            className="h-full w-auto"
            src="/cubeless-logo.png"
            width={100}
            height={40}
            alt="Cubeless"
          />
        </div>
      </div>
      <div className={styles.navLinks}>
        <Auth0LoginButton />
        <br />
        <Auth0SignUpButton />
      </div>
    </div>
  );
};

export default LandingNavbar;
