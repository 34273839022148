import React from 'react';
import CatalogApplicationItem from './CatalogApplicationItem';
import CatalogSortSelect from '../catalog/CatalogSortSelect';
import PaginationControl from '../utilities/PaginationControl';
import { useApplicationsContext } from '../../context/ApplicationsContext';
import useFilteredApplicationList from '../../hooks/useFilteredApplicationList';
import type { UnifiedApplication } from '../../interfaces/Client/Client';
import SearchBar from '../utilities/SearchInputBar';
import CatalogCategoryControls from '../catalog/CatalogCategoryControls';
import { CatalogEmpty } from '../catalog/CatalogEmpty';

const AppCatalogSection: React.FC = () => {
    const {
        organizationId,
        optimisticState: { catalogApplications = [] },
        handleEnableCatalogApp,
        actionLoading,
        categories,
        topCategory,
    } = useApplicationsContext();

    const {
        searchTerm,
        setSearchTerm,
        filteredAndSortedCatalogApplications,
        currentPage,
        setCurrentPage,
        sortOption,
        setSortOption,
        pageCountCatalogApplications,
        categoryFilter,
        setCategoryFilter,
    } = useFilteredApplicationList([], [], catalogApplications, topCategory);

    const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };

    return (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-2xl font-semibold mb-2">Application Catalog</h2>
            <p className="text-sm text-gray-600 mb-4">
                Enable or configure SSO integration for applications in the catalog to allow organization members to access
                these applications.
            </p>
            <div className="sticky top-0 z-10">
                <div className="flex flex-col gap-4 items-end justify-end">
                    <div className="flex flex-col sm:flex-row gap-2 justify-end items-center w-1/2">
                        <SearchBar value={searchTerm} onChange={setSearchTerm} className="flex-auto" />
                        <CatalogSortSelect value={sortOption.value} onChange={setSortOption} />
                    </div>
                    <CatalogCategoryControls
                        applications={catalogApplications}
                        categories={categories}
                        defaultTopCategory
                        onSelectCategory={setCategoryFilter}
                        selectedCategory={categoryFilter}
                    />
                </div>
            </div>

            <div className="py-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-6 gap-6">
                    {filteredAndSortedCatalogApplications.map(
                        (application: UnifiedApplication, index: React.Key | null | undefined) => (
                            <CatalogApplicationItem
                                key={index}
                                application={application}
                                organizationId={organizationId as string | undefined}
                                onEnableCatalogApp={handleEnableCatalogApp}
                                actionLoading={actionLoading[application.uuid]}
                            />
                        ),
                    )}

                    {/* Empty state for catalog */}
                    {filteredAndSortedCatalogApplications.length === 0 && (
                        <CatalogEmpty onClick={() => setCategoryFilter('')} />
                    )}
                </div>
                {pageCountCatalogApplications > 1 && (
                    <PaginationControl
                        pageCount={pageCountCatalogApplications}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                    />
                )}
            </div>
        </div>
    );
};

export default AppCatalogSection;
