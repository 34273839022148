import React, { useEffect, useState } from 'react';
import CustomApplicationItem from './CustomApplicationItem';
import CustomApplicationForm from '../forms/CustomApplicationForm';
import PaginationControl from '../utilities/PaginationControl';
import { SquaresPlusIcon } from '@heroicons/react/20/solid';
import { useApplicationsContext } from '../../context/ApplicationsContext';
import useFilteredApplicationList from '../../hooks/useFilteredApplicationList';
import CatalogSortSelect from '../catalog/CatalogSortSelect';
import SearchBar from '../utilities/SearchInputBar';

const CustomApplicationsSection: React.FC = () => {
  const {
    optimisticState: { customApplications: currentCustomApps } = { customApplications: [] },
    addCustomApplication,
    deleteCustomApplication,
    fetchUrlClients,
  } = useApplicationsContext();

  const [isAddAppModalOpen, setIsAddAppModalOpen] = useState(false);

  const handleAddAppModalClose = () => {
    setIsAddAppModalOpen(false);
  };

  const handleAddAppModalOpen = () => {
    setIsAddAppModalOpen(true);
  };

  useEffect(() => {
    fetchUrlClients();
  }, [fetchUrlClients]);

  const {
    searchTerm,
    setSearchTerm,
    filteredAndSortedUrlClients,
    currentPage,
    setCurrentPage,
    sortOption,
    setSortOption,
    pageCountUrlClients,
  } = useFilteredApplicationList(currentCustomApps, [], []);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="sticky top-0 z-10">
        <h2 className="text-2xl font-semibold mb-2">Custom Applications</h2>
        <p className="text-sm text-gray-600 mb-4">
          Add custom applications by name and URL for organization members to access these applications.
        </p>
        <div className="flex flex-col gap-4 items-end justify-end">
          <div className="flex flex-col sm:flex-row gap-2 justify-end items-center w-1/2">
            <SearchBar value={searchTerm} onChange={setSearchTerm} className="flex-auto" />
            <CatalogSortSelect value={sortOption.value} onChange={setSortOption} />
            <button
              onClick={handleAddAppModalOpen}
              className="rounded-xl bg-seaBlue px-4 py-2.5 text-sm font-semibold text-white shadow-lg hover:bg-sun hover:text-slate focus:outline-none focus:ring-2 focus:ring-seaBlue focus:ring-offset-1 whitespace-nowrap"
            >
              <div className="flex items-center justify-between">
                <SquaresPlusIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                Add Application
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="py-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-6 gap-6">
          {filteredAndSortedUrlClients.map((application, index) => (
            <CustomApplicationItem
              key={index}
              application={application}
              onDelete={deleteCustomApplication}
              isRemovable
            />
          ))}
        </div>
        {pageCountUrlClients > 1 && (
          <PaginationControl
            pageCount={pageCountUrlClients}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
      {isAddAppModalOpen && (
        <CustomApplicationForm
          isOpen={isAddAppModalOpen}
          onClose={handleAddAppModalClose}
          onSubmit={addCustomApplication}
        />
      )}
    </div>
  );
};

export default CustomApplicationsSection;
