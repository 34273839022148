import type { Dispatch, SetStateAction } from 'react';
import { type UnifiedApplication } from '../../../interfaces/Client/Client';

export interface FormData {
  newUrl: string;
  callbackUrl: string;
  newCallbackUrl: string;
}

export interface Message {
  text: string;
  type: 'success' | 'error' | 'info';
}

export interface State {
  formData: FormData;
  message: Message;
  isUpdating: boolean;
  samlEndpoint: string;
  signingKeyCert: string;
  entityId: string;
  isFullCertShown: boolean;
  isNewClientCreated: boolean;
  callbackUrl: string;
  currentStep: string;
  application: UnifiedApplication | null;
  showSuccessMessage: boolean;
}

export type Action =
  | { type: 'OPEN_MODAL'; payload: UnifiedApplication }
  | { type: 'CLOSE_MODAL' }
  | { type: 'SET_FORM_DATA'; payload: Partial<FormData> }
  | { type: 'SET_MESSAGE'; payload: Message }
  | { type: 'SET_UPDATING'; payload: boolean }
  | { type: 'SSO_CONFIG_SUCCESS'; payload: { samlEndpoint: string; signingKey: string; entityId: string } }
  | { type: 'SET_FULL_CERT_SHOWN'; payload: boolean }
  | { type: 'SSO_CONFIG_ERROR'; payload: string }
  | { type: 'UPDATE_URL_SUCCESS'; payload: string }
  | { type: 'UPDATE_URL_ERROR'; payload: string }
  | { type: 'SET_NEW_CLIENT_CREATED'; payload: boolean }
  | { type: 'UPDATE_CALLBACK_URL_SUCCESS'; payload: string }
  | { type: 'SET_CURRENT_STEP'; payload: string }
  | { type: 'SET_SUCCESS'; payload: boolean };

export interface StepProps {
  state: State;
  dispatch: Dispatch<Action>;
  sso?: boolean;
  hasSSOCounterpart?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const initialState = (application?: UnifiedApplication): State => ({
  formData: {
    newUrl: '',
    callbackUrl: application?.callbacks?.[0] || '',
    newCallbackUrl: '',
  },
  message: { text: '', type: 'info' },
  isUpdating: false,
  samlEndpoint: '',
  signingKeyCert: application?.signing_keys?.[0]?.cert?.toString() || '',
  entityId: '',
  isFullCertShown: false,
  isNewClientCreated: false,
  callbackUrl: application?.callbacks?.[0] || '',
  currentStep: 'initial',
  application: application || null,
  showSuccessMessage: false,
});

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        formData: {
          ...state.formData,
          callbackUrl: action.payload.callbacks?.[0] || '',
        },
        currentStep: action.payload.customUrl?.includes('samlp') ? 'reviewSSOConfig' : 'initial',
        application: action.payload,
      };
    case 'CLOSE_MODAL':
      return initialState(state.application || undefined);
    case 'SET_FORM_DATA':
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        message: action.payload,
      };
    case 'SET_UPDATING':
      return {
        ...state,
        isUpdating: action.payload,
      };
    case 'SSO_CONFIG_SUCCESS':
      return {
        ...state,
        samlEndpoint: action.payload.samlEndpoint,
        signingKeyCert: action.payload.signingKey,
        entityId: action.payload.entityId,
        message: { text: 'SSO configuration updated successfully.', type: 'success' },
        currentStep: 'reviewSSOConfig',
        isNewClientCreated: true,
      };
    case 'SSO_CONFIG_ERROR':
      return {
        ...state,
        message: { text: action.payload, type: 'error' },
      };
    case 'UPDATE_URL_SUCCESS':
      return {
        ...state,
        message: { text: `Application URL updated successfully to ${action.payload}.`, type: 'success' },
        isUpdating: false,
        showSuccessMessage: true,
      };
    case 'UPDATE_URL_ERROR':
      return {
        ...state,
        message: { text: action.payload, type: 'error' },
      };
    case 'SET_NEW_CLIENT_CREATED':
      return {
        ...state,
        isNewClientCreated: action.payload,
      };
    case 'UPDATE_CALLBACK_URL_SUCCESS':
      return {
        ...state,
        callbackUrl: action.payload,
        message: { text: 'Callback Url for SSO Application successfully updated!', type: 'success' },
      };
    case 'SET_CURRENT_STEP':
      return {
        ...state,
        currentStep: action.payload,
      };
    case 'SET_FULL_CERT_SHOWN':
      return {
        ...state,
        isFullCertShown: action.payload,
      };
    case 'SET_SUCCESS':
      return {
        ...state,
        showSuccessMessage: action.payload,
      };
    default:
      return state;
  }
};

export interface UpdateApplicationModalProps {
  application: UnifiedApplication;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  organizationId: string;
  userSub: string;
  sso?: boolean;
  hasSSOCounterpart?: boolean;
}
