import React from 'react';
import Image from 'next/image';
import router from 'next/router';

const HomeNavbar = () => {
  const signOutAuth0 = async () => {
    try {
      await router.push('/api/auth/logout');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-white flex justify-between items-center" style={{ height: '64px' }}>
      <div className="flex-1">
        <div className="h-0 flex flex-shrink-0 items-center px-10">
          <div className="relative h-10 w-auto">
            <Image className="h-full w-auto" src="/cubeless-logo.png" width={100} height={40} alt="Cubeless" />
          </div>
          <div className="flex items-center px-[30px]">
            <Image src="/assets/vertical-dash.png" alt="seperator" className="h-41 w-auto" width={40} height={40} />
          </div>
        </div>
      </div>
      <div className="mx-auto py-6 px-6">
        <button
          className="mr-4 inline-flex items-center bg-[#fff] hover:bg-[#FFB238] text-black py-2 px-4 rounded-full"
          type="button"
          onClick={signOutAuth0}
        >
          <span className="text-black font-semibold">RESTART</span>
        </button>
      </div>
    </div>
  );
};

export default HomeNavbar;
