import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { deleteSSOClient } from '../../services';
import Spinner from '../utilities/Spinner';

interface SSODeletionConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleDeleteSSOClient?: (clientId: string) => Promise<void>;
  message: JSX.Element;
  applicationName: string;
  clientId: string;
}

const SSODeletionConfirmationModal: React.FC<SSODeletionConfirmationModalProps> = ({
  isOpen,
  onClose,
  message,
  applicationName,
  clientId,
  handleDeleteSSOClient,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    if (inputValue.toLowerCase() === applicationName.toLowerCase()) {
      setError('');
      setIsLoading(true);

      try {
        if (handleDeleteSSOClient) {
          await handleDeleteSSOClient(clientId);
        } else {
          await deleteSSOClient(clientId);
        }

        onClose();
      } catch (error) {
        console.error('Error deleting client:', error);
        setError('An unexpected error occurred while deleting the client. Please try again.');
        setInputValue(''); // Clear the input field
      } finally {
        setIsLoading(false);
      }
    } else {
      setError('You must enter the correct application name to confirm.');
    }
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" open={isOpen} onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-2xl min-h-[110%] p-6 my-14 overflow-none text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="text-center">
                <span className="text-md">{message}</span>
              </div>
              <input
                type="text"
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                className="mt-8 border rounded p-2 w-full"
                placeholder="Enter SSO configured application name to confirm deletion"
              />
              {error && <div className="text-red-500 mt-2">{error}</div>}
              <div className="flex justify-center space-x-2 my-6">
                {isLoading ? (
                  <div className="flex justify-center items-center w-full">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <button onClick={onClose} className="px-3 py-2 rounded text-white bg-gray-400">
                      Cancel
                    </button>
                    <button
                      onClick={handleConfirm}
                      className="px-3 py-2 rounded text-white bg-red-600 relative overflow-hidden"
                    >
                      <div className="flex items-right justify-center">
                        <span className={`transition-opacity duration-300 ${isLoading ? 'opacity-0' : 'opacity-100'}`}>
                          Confirm
                        </span>
                      </div>
                    </button>
                  </>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SSODeletionConfirmationModal;
