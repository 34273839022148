import React from 'react';
import SelectedApplicationItem from './SelectedApplicationItem';
import CatalogSortSelect from '../catalog/CatalogSortSelect';
import PaginationControl from '../utilities/PaginationControl';
import { useApplicationsContext } from '../../context/ApplicationsContext';
import useFilteredApplicationList from '../../hooks/useFilteredApplicationList';
import SearchBar from '../utilities/SearchInputBar';

const SelectedApplicationsSection: React.FC = () => {
  const {
    organizationId,
    optimisticState: { selectedApplications: currentSelectedApps },
    handleSelectedToggleStatus,
    handleDeleteSSOClient,
    actionLoading,
  } = useApplicationsContext();

  const {
    searchTerm,
    setSearchTerm,
    filteredAndSortedSelectedApplications,
    currentPage,
    setCurrentPage,
    sortOption,
    setSortOption,
    pageCountSelectedApplications,
  } = useFilteredApplicationList([], currentSelectedApps, []);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="sticky top-0 z-10">
        <div className="flex flex-col gap-4 items-end justify-end">
          <div className="flex flex-col sm:flex-row gap-2 justify-end items-center w-1/2">
            <SearchBar value={searchTerm} onChange={setSearchTerm} className="flex-auto" />
            <CatalogSortSelect value={sortOption.value} onChange={setSortOption} />
          </div>
        </div>
      </div>

      <div className="py-8">
        <h2 className="text-2xl font-semibold mb-2">Selected Applications</h2>
        <p className="text-sm text-gray-600 mb-4">
          These are the current enabled or SSO configured applications accessible to organization members. SSO
          configured applications will have your organization name prefixed to the application name and display an SSO
          badge.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-6 gap-6">
          {filteredAndSortedSelectedApplications.map((application, index) => (
            <SelectedApplicationItem
              key={index}
              application={application}
              onToggle={handleSelectedToggleStatus}
              isRemovable
              organizationId={organizationId || ''}
              handleDeleteSSOClient={() => handleDeleteSSOClient(application.uuid)}
              actionLoading={actionLoading[application.uuid]}
            />
          ))}
        </div>
        {pageCountSelectedApplications > 1 && (
          <PaginationControl
            pageCount={pageCountSelectedApplications}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default SelectedApplicationsSection;
