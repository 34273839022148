import React, { useEffect, useReducer } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { initialState, reducer, type UpdateApplicationModalProps } from './UpdateApplicationModalState';
import InitialStep from './Steps/IntitialStep';
import UpdateUrlStep from './Steps/UpdateUrlStep';
import ConfigureSSOStep from './Steps/ConfigureSSOStep';
import ReviewSSOConfigStep from './Steps/ReviewSSOConfigStep';
import { useApplicationsContext } from '../../../context/ApplicationsContext';
import { convertUrlToHttps, validateUrl } from '../../../utils';

const UpdateApplicationModal: React.FC<UpdateApplicationModalProps> = ({
  application,
  isOpen,
  setIsOpen,
  organizationId,
  userSub,
  sso,
  hasSSOCounterpart,
}) => {
  const { configureSSO, updateApplication, fetchUnifiedApplications, updateSSOClientCallbacks } =
    useApplicationsContext();
  const [state, dispatch] = useReducer(reducer, initialState(application));

  useEffect(() => {
    if (isOpen) {
      dispatch({ type: 'OPEN_MODAL', payload: application });
    }
  }, [isOpen, application]);

  const handleClose = () => {
    if (!state.isNewClientCreated && !state.isUpdating) {
      setIsOpen(false);
      dispatch({ type: 'CLOSE_MODAL' });
    }
  };

  const handleSSOConfiguration = async () => {
    dispatch({ type: 'SET_UPDATING', payload: true });

    try {
      const { samlEndpoint, signingKey, entityId } = await configureSSO(
        userSub,
        application,
        state.formData.callbackUrl,
      );

      dispatch({ type: 'SSO_CONFIG_SUCCESS', payload: { samlEndpoint, signingKey, entityId } });
    } catch (error) {
      dispatch({ type: 'SSO_CONFIG_ERROR', payload: 'An error occurred while configuring SSO.' });
    } finally {
      dispatch({ type: 'SET_UPDATING', payload: false });
    }
  };

  const handleUpdateUrl = async () => {
    if (!state.formData.newUrl.trim()) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: 'URL cannot be empty. Please enter a valid URL.', type: 'error' },
      });

      return;
    }

    dispatch({ type: 'SET_UPDATING', payload: true });

    try {
      const { isValid, web } = await validateUrl(state.formData.newUrl);

      if (!isValid) {
        dispatch({ type: 'SET_MESSAGE', payload: { text: 'Invalid URL', type: 'error' } });
        dispatch({ type: 'SET_UPDATING', payload: false });

        return;
      }

      const finalUrl = web ? await convertUrlToHttps(state.formData.newUrl) : state.formData.newUrl;

      const updateData = { organizationId, applicationId: application.applicationId as string, customUrl: finalUrl };

      await updateApplication(application.applicationId as string, updateData);

      dispatch({ type: 'UPDATE_URL_SUCCESS', payload: finalUrl });

      setTimeout(async () => {
        await fetchUnifiedApplications();
      }, 1000);
    } catch (error) {
      dispatch({ type: 'UPDATE_URL_ERROR', payload: 'An error occurred while updating the application URL.' });
      dispatch({ type: 'SET_UPDATING', payload: false });
    }
  };

  const finalizeNewSSOSetup = async () => {
    dispatch({ type: 'SET_MESSAGE', payload: { text: 'SSO Application successfully created!', type: 'success' } });
    dispatch({ type: 'SET_NEW_CLIENT_CREATED', payload: false });
    await fetchUnifiedApplications();
    setIsOpen(false);
  };

  const finalizeUpdateSSOSetup = async () => {
    dispatch({ type: 'SET_UPDATING', payload: true });

    if (!state.formData.newCallbackUrl.trim()) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: 'Callback URL cannot be empty. Please enter a valid URL.', type: 'error' },
      });
      dispatch({ type: 'SET_UPDATING', payload: false });

      return;
    }

    try {
      const { isValid, web } = await validateUrl(state.formData.newCallbackUrl);

      if (!isValid) {
        dispatch({ type: 'SET_MESSAGE', payload: { text: 'Invalid URL', type: 'error' } });
        dispatch({ type: 'SET_UPDATING', payload: false });

        return;
      }

      const callbackUrlToUpdate = web
        ? await convertUrlToHttps(state.formData.newCallbackUrl)
        : state.formData.newCallbackUrl;

      if (callbackUrlToUpdate === state.formData.callbackUrl) {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: 'Callback Url has not been changed and will remain the same.', type: 'info' },
        });
      } else {
        await updateSSOClientCallbacks(application.uuid, callbackUrlToUpdate);
        dispatch({ type: 'UPDATE_CALLBACK_URL_SUCCESS', payload: callbackUrlToUpdate });
      }
    } catch (error) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: 'An error occurred while updating the callback URL.', type: 'error' },
      });
    } finally {
      dispatch({ type: 'SET_UPDATING', payload: false });
      await fetchUnifiedApplications();
    }
  };

  const renderContent = () => {
    switch (state.currentStep) {
      case 'initial':
        return (
          <InitialStep
            state={state}
            dispatch={dispatch}
            sso={sso}
            hasSSOCounterpart={hasSSOCounterpart}
            setIsOpen={setIsOpen}
          />
        );
      case 'updateUrl':
        return (
          <UpdateUrlStep state={state} dispatch={dispatch} handleUpdateUrl={handleUpdateUrl} setIsOpen={setIsOpen} />
        );
      case 'configureSSO':
        return (
          <ConfigureSSOStep
            state={state}
            dispatch={dispatch}
            handleSSOConfiguration={handleSSOConfiguration}
            fetchUnifiedApplications={fetchUnifiedApplications}
            setIsOpen={setIsOpen}
          />
        );
      case 'reviewSSOConfig':
        return (
          <ReviewSSOConfigStep
            state={state}
            dispatch={dispatch}
            finalizeNewSSOSetup={finalizeNewSSOSetup}
            finalizeUpdateSSOSetup={finalizeUpdateSSOSetup}
            setIsOpen={setIsOpen}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" open={isOpen} onClose={handleClose}>
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              {renderContent()}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UpdateApplicationModal;
