import React from 'react';
import { type UnifiedApplication } from '../../interfaces/Client/Client';

interface ApplicationItemProps {
  application: UnifiedApplication;
  organizationId?: string;
  isRemovable?: boolean;
  actionLoading: boolean;
  onEnableCatalogApp: (application: UnifiedApplication) => Promise<void>;
}

const CatalogApplicationItem: React.FC<ApplicationItemProps> = ({ application, actionLoading, onEnableCatalogApp }) => {
  const handleEnable = async () => {
    await onEnableCatalogApp(application);
  };

  // Validate the application logo path or use the default logo path
  const logoPath = application.logo || '/assets/cubeless_white_logo.svg';

  return (
    <div className="flex flex-col items-center justify-between pt-4 bg-white rounded-lg shadow border border-gray-200 space-y-3 max-w-xs transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
      <div className="w-full flex justify-center pb-4">
        <img
          src={logoPath}
          alt={`${application.name} Logo`}
          width={80}
          height={80}
          className="rounded-md aspect-square"
        />
      </div>
      <div className="text-center">
        <a
          href={application.baseUrl || '#'}
          target="_blank"
          rel="noopener noreferrer"
          className="text-md font-medium text-gray-800"
        >
          {application.name}
        </a>
      </div>
      <div className="flex justify-between w-full border-t border-gray-200">
        <button
          onClick={handleEnable}
          className="w-full py-2 text-sm font-medium text-blue-800 hover:bg-gray-100 flex justify-center items-center gap-2"
          disabled={actionLoading}
        >
          {actionLoading ? (
            <>
              <svg
                className="animate-spin h-4 w-4 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              Enabling...
            </>
          ) : (
            'Enable'
          )}
        </button>
      </div>
    </div>
  );
};

export default CatalogApplicationItem;
