import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Spinner } from '../../..';
import { type StepProps } from '../UpdateApplicationModalState';

const UpdateUrlStep: React.FC<StepProps & { handleUpdateUrl: () => void }> = ({ state, dispatch, handleUpdateUrl }) => (
  <>
    <h3 className="mb-4 text-lg leading-6 font-semibold text-gray-900">Update Application URL</h3>
    <div className="text-sm mb-4">
      Enter the new URL for your application. Ensure the URL starts with 'https://' to promote secure access. If your
      application requires specific paths or parameters, include those in the URL.
    </div>
    <div className="mt-4">
      <label htmlFor="url" className="block text-sm font-medium text-gray-700">
        New Application URL
      </label>
      <input
        id="url"
        type="text"
        value={state.formData.newUrl}
        onChange={e => dispatch({ type: 'SET_FORM_DATA', payload: { newUrl: e.target.value } })}
        className="mt-1 block w-full p-2.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
        placeholder={state.application?.customUrl ? `Current: ${state.application.customUrl}` : 'https://example.com'}
      />
    </div>
    <div className="flex justify-between items-center mt-4">
      <button
        className="w-auto h-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        onClick={() => dispatch({ type: 'SET_CURRENT_STEP', payload: 'initial' })}
      >
        <div className="flex items-center">Back to Initial</div>
      </button>
      <button
        onClick={handleUpdateUrl}
        disabled={state.isUpdating}
        className={`w-auto h-auto inline-flex justify-center px-4 py-2 text-sm font-medium text-white ${
          state.isUpdating ? 'bg-gray-400' : 'bg-seaBlue hover:bg-blue-700'
        } rounded-md`}
      >
        <div className="flex items-center">
          {state.isUpdating && <Spinner />}
          {state.isUpdating ? 'Updating...' : 'Update URL'}
        </div>
      </button>
    </div>
    {state.message.type === 'error' && (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-red-700">{state.message.text}</p>
          </div>
        </div>
      </div>
    )}
    {state.message.type === 'success' && (
      <div className="rounded-md bg-green-50 p-4 mt-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-green-700">{state.message.text}</p>
          </div>
        </div>
      </div>
    )}
  </>
);

export default UpdateUrlStep;
