import { useCallback, useMemo, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import CategoryService, {
  type Category,
  type CreateCategoryPayload,
  type UpdateCategoryPayload,
} from '../services/CategoryService';

export type useCategoriesReturnType = {
  categories: Category[];
  error: Error | null;
  isFetching: boolean;
  topCategory: string;
  getCategories: () => Promise<Category[] | undefined>;
  addCategory: (payload: CreateCategoryPayload) => Promise<Category | undefined>;
  updateCategory: (uuid: string, payload: UpdateCategoryPayload) => Promise<Category | undefined>;
  deleteCategory: (uuid: string) => Promise<void>;
};

export const useCategories = (): useCategoriesReturnType => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const topCategory = useMemo(() => {
    return categories.find(cat => cat.name === 'Top')?.uuid ?? '';
  }, [categories]);

  const getCategories = useCallback(async () => {
    try {
      setIsFetching(true);

      const response = await CategoryService.getCategories();

      setError(null);
      setCategories(response);

      return response;
    } catch (error) {
      setError(error as Error);
      console.error('Error fetching categories:', error);
      captureException(error);
    } finally {
      setIsFetching(false);
    }
  }, [setCategories, setError, setIsFetching]);

  const addCategory = useCallback(
    async (payload: CreateCategoryPayload) => {
      try {
        setIsFetching(true);

        const response = await CategoryService.createCategory(payload);

        setError(null);
        setCategories(prevCategories => [...prevCategories, response]);

        return response;
      } catch (error) {
        setError(error as Error);
        console.error('Error creating Category:', error);
        captureException(error);
      } finally {
        setIsFetching(false);
      }
    },
    [setCategories, setError, setIsFetching],
  );

  const updateCategory = useCallback(
    async (uuid: string, payload: UpdateCategoryPayload) => {
      try {
        setIsFetching(true);

        const response = await CategoryService.updateCategory(uuid, payload);

        setError(null);
        setCategories(prevCategories => prevCategories.filter(category => category.uuid !== uuid).concat(response));

        return response;
      } catch (error) {
        setError(error as Error);
        console.error('Error updating Category:', error);
        captureException(error);
      } finally {
        setIsFetching(false);
      }
    },
    [setCategories, setError, setIsFetching],
  );

  const deleteCategory = useCallback(
    async (uuid: string) => {
      try {
        setIsFetching(true);

        const response = await CategoryService.deleteCategory(uuid);

        setCategories(prevCategories => prevCategories.filter(category => category.uuid !== uuid));
        setError(null);

        return response;
      } catch (error) {
        setError(error as Error);
        console.error('Error deleting Category:', error);
        captureException(error);
      } finally {
        setIsFetching(false);
      }
    },
    [setCategories, setError, setIsFetching],
  );

  return {
    categories,
    error,
    isFetching,
    topCategory,
    addCategory,
    deleteCategory,
    getCategories,
    updateCategory,
  };
};
